/* Copyright 2013 - 2022 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getSharepaAppUrl() {
  let url = 'https://app.sharepa.social'

  if (IS_BROWSER && window.SHAREPA_APP_URL === '{{{SHAREPA_APP_URL}}}') {
    delete window.SHAREPA_APP_URL
  }

  if (IS_NODE && process.env.SHAREPA_APP_URL === '{{{SHAREPA_APP_URL}}}') {
    delete process.env.SHAREPA_APP_URL
  }

  if (IS_BROWSER && window.SHAREPA_APP_URL) {
    url = `${window.SHAREPA_APP_URL}`
  } else if (IS_NODE && process.env.SHAREPA_APP_URL) {
    url = process.env.SHAREPA_APP_URL
  } else if (IS_STAGING) {
    url = 'https://app.sharepa-staging.social'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://app.sharepa-staging.social';
    // url = 'http://192.168.1.64:15252';
    url = 'http://localhost:15252'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Sharepa.url = ' + url)
  // }

  return url
}
