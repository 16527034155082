/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import ErrorBoundaryGlobal from '@stiloso/components/ErrorBoundaryGlobal.js'
import captureExceptionBrowser from '@monorepo/telemetry/captureExceptionBrowser.js'

const ErrorBoundaryGlobalWithSentry = ({ children }) => (
  <ErrorBoundaryGlobal onCatchError={captureExceptionBrowser}>
    {children}
  </ErrorBoundaryGlobal>
)

export default ErrorBoundaryGlobalWithSentry
