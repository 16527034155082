/* Copyright 2013 - 2022 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import { initMultilocale } from '@multilocale/react/index.js'
import MultilocaleProvider from '@multilocale/react/MultilocaleProvider.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import ErrorBoundaryGlobalWithSentry from '@stiloso/components/ErrorBoundaryGlobalWithSentry.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import faviconSvg from '@sharepa/shared/favicon.svg' // eslint-disable-line no-unused-vars -- needed to generate asse.js'
import product from '@sharepa/shared/product.js'
import GlobalStyles from '@sharepa/shared/GlobalStyles.js'
import Routes from './Routes.js'
import service from './service.js'

allowSubdomainsCrossOrigin()

initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: `${product}-${service}`,
})

createRoot(document.getElementById('root')).render(
  <ErrorBoundaryGlobalWithSentry>
    <BrowserRouter>
      <MultilocaleProvider>
        <Suspense>
          <ScrollToTop />
          <GlobalStyles />
          <Routes />
          <StageBadge />
        </Suspense>
      </MultilocaleProvider>
    </BrowserRouter>
  </ErrorBoundaryGlobalWithSentry>,
)

getSentryBrowser()
